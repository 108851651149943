import React, { useState, useMemo } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

// Assume this import path is correct for your project structure
import blog1 from '../assets/Blog/Blog Banner.jpg';
import blog2 from '../assets/Blog/Diwali-2024.jpg';
import blog3 from '../assets/Blog/Evolution.jpg';
import blog4 from '../assets/Blog/Unlock-Prosperity.jpg';
import portfolioBg from '../components/Image/Resolute-UI-Portfolio.jpg';
import { Helmet } from 'react-helmet';

// Sample blog post data
const blogPosts = [
  {
    id: 1,
    title: "3 Guardrails of Investing: Essential Tips for Long-Term Success",
    excerpt: "Over the years we have witnessed how the stigma attached to investment that it is a highly risky business has subsided significantly. Today, ....",
    content: `..investing is often seen as a pathway to wealth, but without a clear strategy and well-defined principles, it can also lead to costly mistakes.
    1. Do Not Follow the Herd
One of the biggest mistakes that many investors make is blindly following the crowd. When markets soar, there's often a frenzy where people rush to invest in trending assets, fearing they might miss out on big returns. This herd mentality is driven by immediate emotions, but it can lead to poor investment decisions. Try avoiding it and invest judiciously! Let us look at some of the reasons as to why we shouldn't follow the crowd. 

a) Market Timing is Unreliable: Attempting to time the market based on popular trends can often backfire. By the time you join the crowd, the best opportunities may have passed.
b) Emotions Can Cloud Judgment: Crowds are often driven by emotion rather than logic. Decisions based on fear or greed are likely to be shortsighted and harmful in the long run.

So, instead of jumping on the latest investment bandwagon, focus on making decisions based on thorough research and your personal financial goals. This will help you remain objective and avoid making hasty choices that can derail your financial strategy.

2. Always Think Long-Term with Proper Planning
Investing is not about getting rich overnight. It's a marathon, not a sprint. A long-term investment strategy, combined with well-thought-out planning, can yield far greater results than short-term, reactionary decisions. How about we understand the power of Long-Term thinking:

a) Compounding Returns: The longer you stay invested, the more your investments benefit from compound interest. This is particularly true for assets like stocks or real estate, where time allows value to grow exponentially.
b) Planning for the Future: Planning for the long-term is important in investing because it helps you reduce risks and grow your money over time. It allows you to handle market ups and downs, take advantage of compounding, and stay on track to reach your financial goals like retirement or other big future needs. Here are a few ways to plan.

i) Set Clear Goals: Define your short, mid, and long-term financial objectives. Whether it's retirement, buying a house, or starting a business, knowing your goals will guide your investment decisions.
ii) Assess Your Risk Profile: Understand how much risk you are comfortable with. Younger investors with a longer horizon can afford to take on more risk, while older investors may want to preserve capital with safer investments.
iii) Regularly Review and Adjust Your Plan: Life changes, and so should your investment strategy. Make sure to review your financial plan periodically to ensure that it aligns with your current situation.

c) Riding Out Market Volatility: Financial markets can be volatile in the short term, but over time, they tend to rise. A long-term mindset helps you endure market corrections or crashes without panicking.

3. Diversification of Assets: The Need of the Hour
"Don't put all your eggs in one basket" is one of the oldest investment adages, and it still rings true today. Diversification is spreading your investments across various asset classes which helps reduce risk and improve the potential for returns.

a) Why Diversification is Key: 
i) Minimizing Risk: Different asset classes (stocks, bonds, real estate, commodities) react differently to market conditions. If one sector underperforms, another might perform well, balancing your overall portfolio.
ii) Capitalizing on Opportunities: Diversifying your investments allows you to tap into multiple growth areas. For instance, while stocks may provide significant long-term returns, bonds or gold can act as a hedge during market downturns.

b) The Right Way to Diversify:
i) Asset Allocation: Determine the right mix of assets based on your risk tolerance and financial goals. For instance, a mix of equities for growth and bonds for stability creates a balanced approach.
ii) Geographical Diversification: Don't limit your investments to one country. Global markets offer opportunities that can complement your local investments and reduce geopolitical risks.
iii) Consider Alternative Investments: Beyond traditional asset classes, modern investors are exploring options like fractional real estate, cryptocurrencies, or peer-to-peer lending. However, approach these with caution and ensure they fit within your overall strategy.

Conclusion: Stay Focused, Stay Informed
Investing can be an incredible tool for building wealth, but it requires discipline, strategy, and patience. By avoiding the herd mentality, maintaining a long-term focus, and diversifying your assets, you set yourself up for sustainable financial success. The key is to stay informed and stick to your plan, adjusting when necessary but never letting emotions like fear or haste dictate your decisions. Remember, wealth-building is a journey, not a race. Embrace these three guardrails, and you'll be better equipped to navigate the unpredictable world of investing!`,
    author: "Resolute Corp",
    date: "30-09-2024",
    category: "Investing",
    banner: blog1,
  },
  {
    id: 2,
    title: "Diwali 2024: Why Investing in Index Funds Is the Best Bet for Long-Term Wealth Creation",
    excerpt: "Diwali marks a time of new beginnings, prosperity, and thoughtful investments. This festive season, if you're considering ways to grow your wealth steadily,...",
    content: ` ..investing in large-cap equities through index funds or ETFs (Exchange-Traded Funds) is a smart, strategic move. Index funds are a solid bet on India’s economic growth story,one that has consistently shown resilience and potential over time. This Diwali, build your wealth wisely by investing in index funds. Let’s break this for you. In this blog, we will explore why index funds, particularly the SIP (Systematic Investment Plan) route, are your best option for long-term wealth creation.

    Betting on India's Growth Story
              India's economy is on an upward trajectory, and while individual stocks and sectors can fluctuate, the broader indices, such as Nifty 50, Nifty 100, and Nifty 500, provide exposure to the entire spectrum of the country’s corporate giants. By investing in these indices, you’re essentially betting on India’s long-term economic growth.

              Unlike specific stocks or sectors that can rise or fall with industry trends, an index fund spreads your investment across a wide range of companies. As the Indian economy grows, so do the companies within these indices. While individual stocks may face challenges or even disappear, indices are a resilient representation of the market at large, making them a safer, long-term investment.

              Why Index Funds?

              Index funds are passively managed mutual funds that mirror the performance of a specific benchmark index. They don't rely on fund managers' expertise or individual stock picking. Instead, they automatically invest in the companies that make up the index, such as Nifty 50 or Nifty 100, in the same proportion as their weighting in the index. This eliminates the guesswork, giving you broad exposure to the overall market.

              Key advantages include:

              1. Low Expense Ratio: Since these funds are passively managed, they come with lower fees than actively managed funds.

              2. Low Fund Manager Risk: With no stock selection involved, there's less risk of human error or bias.

              3. Market-Linked Returns: Index funds typically follow the overall performance of the market, making them a reliable option for long-term growth.

              Top Index Funds to Consider

              Some of the key index funds in India that offer broad market exposure include:

              1. Nifty 50 Index Funds: Representing 50 of the largest companies on the NSE, Nifty 50 funds are the go-to for exposure to the market’s giants. These funds are perfect for those looking for long-term, stable returns.

              2. Nifty Next 50 Index Funds: For those interested in emerging blue-chip companies, this index represents the next wave of potential Nifty 50 companies. These stocks typically offer higher growth potential but come with slightly more risk.

              3. Nifty 500 Index Funds: Covering a broader spectrum, these funds represent companies across large, mid, and small-cap segments. Ideal for those who want more diversity in their portfolio.

              The Power of SIP: Invest Regularly, Reap Big Returns

              The most effective way to build wealth with index funds is through Systematic Investment Plans (SIP). SIPs allow you to invest a fixed amount regularly, regardless of market conditions. Here’s why SIPs are a winning strategy:

              1. Rupee Cost Averaging: By investing at regular intervals, you buy more units when prices are low and fewer when they are high, effectively reducing the average cost of your investment.

              2. Disciplined Investment: SIPs inculcate a habit of regular investing, which is crucial for long-term financial goals.

              3. Compounding Effect: Over time, your investments grow not just on your principal, but also on the returns generated, thanks to the power of compounding.

              The Long-Term Play: Ignore the Noise, Focus on Growth

              While market volatility and short-term fluctuations may make headlines, it’s important to stay focused on your long-term goals. Over time, equity markets have consistently outperformed other asset classes, and index funds are an excellent way to participate in this growth.

              The key is to not get swayed by short-term market movements or sector-specific news. Whether certain industries are in favour or not, the economy as a whole will keep

              evolving, and your investment in a diversified index fund will capture that long-term growth.

              How Index Funds Are Better Than Individual Stocks?

              Investing in individual stocks can be risky, as picking the right stock at the right time requires expertise, research, and a bit of luck. Even the most promising companies can face unforeseen challenges. On the other hand, an index fund spreads that risk across many companies, reducing the likelihood of major losses due to the poor performance of a single stock. As sectors rotate in and out of favour, index funds ensure you stay invested in the companies that are driving economic growth.

              Conclusion

              As you plan your financial future this Diwali, consider investing in index funds for long-term wealth creation. Whether it’s Nifty 50, Nifty 100, or even Nifty Next 50, these funds offer a low-risk, cost-effective, and reliable way to tap into India’s growth potential. When combined with the disciplined approach of SIPs, index funds can help you unlock steady prosperity in the years to come.
              So, this Diwali, bet on India and watch your wealth grow with it.`,

              author: "Resolute Corp",
              date: "29-10-2024",
              category: "Investing",
              banner: blog2,
            },
            {
              id: 3,
              title:"Evolution of Investment over the Last Decade",
              excerpt:"In today’s rapidly shifting and highly competitive financial world, investing has become a necessity rather than an option. With modern spending habits – spending more, saving less, and sometimes incurring unnecessary debts and heavy losses, aspiration for financial independence can be difficult to fulfil. However, reaching financial security is still within your reach; it’s just about deliberate action and wise investment choices, especially in this hour when a second income has become a pressing need. Here’s a guide on how to adapt to this new landscape and secure your financial future...",
              content:`Why Investing Has Become a Financial Imperative?

                    The global economy is evolving, making inflation and lifestyle inflation harder to counterbalance with savings alone. Here’s how you can embrace this new investment landscape and secure your financial future:

                    1. Building a Strong Financial Foundation

                    Every successful financial journey starts with disciplined savings. Try saving at least 20% to 30% of your income, increasing this rate as your salary grows, and always prioritize saving first, spending later. Avoid the trap of lifestyle inflation, where lifestyle upgrades happen faster than income growth, leaving little room for savings. To create a strong financial base, set up an emergency fund covering at least 3–6 months of expenses. This will serve as a buffer against unexpected financial pressures, freeing you to invest with peace of mind.

                    2. Designing an Investment Portfolio: Balancing Growth and Preservation

                    An effective portfolio balances wealth preservation with growth, tailored to every individual’s risk tolerance and goals. Allocate investments across asset classes like equities, bonds, and real estate, focusing on a starting structure of about 70% in wealth-preserving assets (e.g., large-cap stocks, bonds) and 30% in growth-oriented investments (e.g., equities, emerging markets). This balance provides stability while allowing for growth. For beginners wary of high risk, index funds and ETFs offer diversified exposure with less active management, as broad-market indexes like the Nifty 50 or S&P 500 tend to deliver reliable, long-term growth.

                    3. Diversification: The Key to a Resilient Portfolio

                    In investing, diversification is essential. Within equities, spread investments across sectors like technology, healthcare, and consumer goods to reduce risk. Diversifying globally further protects your portfolio, as international markets may outperform domestic ones in certain cycles. Real estate, particularly through fractional ownership or Real Estate Investment Trusts (REITs), can offer strong long-term growth and regular cash flows without heavy upfront capital. Additionally, incorporating alternative assets,

                    such as precious metals or cryptocurrencies, adds resilience and acts as a hedge against market volatility and inflation.

                    4. Establishing Cash Flows for Stability

                    Consistent cash flow is vital for portfolio resilience, especially during economic downturns. Passive income sources like dividend-paying stocks, bonds, or rental real estate ensure you can handle financial volatility without selling investments at a loss. Rental properties, for instance, can provide both asset appreciation and monthly income, particularly in high-demand areas. Establishing reliable cash flows not only keeps you afloat during rough patches but allows you to reinvest and grow your portfolio over time.

                    5. Leveraging the Power of Compounding and Long-Term Growth

                    Compounding can transform modest investments into significant wealth over time, making it essential to start investing early. Tools like Systematic Investment Plans (SIPs) automate contributions, adding consistency to your strategy. SIPs are ideal for investors focused on gradual, sustained growth rather than timing the market. Consistent contributions to SIPs allow for the power of compounding to take effect, amplifying gains over the years and yielding impressive long-term results.

                    6. Adapting to Market Changes and Staying Flexible

                    Financial markets are dynamic, so periodically revisit your investment strategy to ensure alignment with your goals and risk tolerance. Adjustments may be necessary as your circumstances evolve with age, income, and life stages. Staying informed on market trends enables you to make timely, informed decisions that enhance your portfolio's resilience. Do not hesitate to seek guidance from financial advisors or consultancies, which provide data-driven insights and make investing accessible even to beginners.

                    7. Embracing Technology in Modern Investing

                    Technology has made investing accessible and smarter than ever. Robo-advisors, for example, offer personalized, data-driven recommendations based on your risk profile, simplifying complex investment decisions. Exploring digital assets like cryptocurrency is an option for the risk-tolerant, but even traditional investors can benefit from fintech solutions that provide powerful tools for budgeting, saving, and planning. Embracing technology enhances your investment experience and ensures you stay current in a rapidly evolving financial world.

                    8. Developing a Comprehensive Financial Plan

                    Setting clear financial goals is essential for any investor. Define long-term ambitions, such as retirement or homeownership, as well as short-term goals like emergency

                    funds. Regularly assess your progress and make adjustments as needed, ensuring that your savings and investment plans remain on track. Tracking your financial journey closely, adapting when necessary, and seeking professional advice when needed ensure you remain on course to meet your financial objectives.

                    Conclusion

                    In the modern financial landscape, investing is crucial for a stable, prosperous future. By focusing on saving, diversifying, creating cash flows, and leveraging modern investment tools, you can achieve financial security and independence. The journey may require consistency, discipline, and adaptability, but taking control of your finances today will allow you to embrace a secure, fulfilling tomorrow. Start now, make informed decisions, and take deliberate steps toward a financially strong future.`,
              author: "Resolute Corp",
              date: "29-10-2024",
              category: "Investing",
              banner: blog3,
            },
            { 
              id: 4,
              title:"Unlock Prosperity this Dhanteras: 3 Strategic Ways to Invest in Gold",
              excerpt:"As the festive season approaches, Dhanteras, a day dedicated to wealth and prosperity, takes on special significance for many. Buying gold on this auspicious day is more than just a tradition, it is considered a prudent financial investment, ensuring wealth accumulation and security for years to come. However, with changing times, the methods of investing in gold have evolved a lot. Instead of simply buying physical gold, modern investors now have options that offer convenience, flexibility, and even better financial returns.",
              content:`In this blog, we’ll explore three strategic ways to invest in gold this Dhanteras that promise prosperity and long-term financial gains.

1. Gold ETFs: The Investor's Favourite

Gold Exchange Traded Funds (ETFs) have become one of the most popular ways to invest in gold. They allow investors to track the price of gold without physically holding it, eliminating storage worries and reducing additional costs like making charges.

Why Choose Gold ETFs?

a) Easy to Trade: Like stocks, Gold ETFs are listed and traded on stock exchanges, making them highly liquid. You can buy and sell Gold ETFs through your broker with ease.

b) No Storage Worries: Since you’re not buying physical gold, you don’t need to worry about theft, safekeeping, or locker charges.

c) Cost Efficiency: ETFs carry lower transaction costs compared to physical gold because you avoid the overhead of making charges and purity concerns.

Things to Consider

a) Management Fees: While Gold ETFs come with lower costs compared to physical gold, they still carry some asset management fees. Long-term capital gains on ETFs are taxable.

For investors seeking to combine convenience with gold price exposure, Gold ETFs provide a hassle-free and cost-effective solution.

2. Digital Gold: The Future of Gold Investment

Digital Gold is the new-age method of investing in the precious metal, offering the ease of purchasing small quantities with a few clicks. Particularly appealing to Gen Z and millennials, it combines modern payment methods with a traditional investment approach.


Advantages of Digital Gold

a) Small-Scale Investment: With digital gold, you can start investing with small amounts, making it accessible to investors of all levels.

b) Purchase Flexibility: You can buy digital gold in real-time using various platforms, including UPI or EMIs. Over time, these small investments can be converted into tangible assets such as jewellery or physical gold.

b) Convenient: Digital gold purchases eliminate the need for physical storage. Reputed companies, such as MMTC, store the gold on your behalf in secure vaults.

Key Considerations

a) Buy from Trusted Sources: Since digital gold is still a relatively new concept, it’s essential to buy only from reputable platforms like MMTC or other government-certified entities to ensure the security of your investment.

By investing in digital gold, you can accumulate a wealth of gold over time with unmatched ease and convenience.

3. Gold Coins: A Traditional and Practical Investment

For those who prefer a physical connection to their gold investment, gold coins offer a reliable and cost-effective alternative to jewellery. Unlike ornaments, gold coins come with minimal making charges, making them a purer form of investment.

Benefits of Gold Coins

a) Lower Making Charges: Compared to jewellery, gold coins involve lower making charges, making them a better choice for pure investment purposes.

b) Varied Denominations: Gold coins are available in various denominations, allowing flexibility in how much you invest based on your budget.

Challenges to Consider

a) Storage: While gold coins offer a more practical investment compared to jewellery, you still need to consider storage security. However, they can be easily stored in secure home lockers or bank vaults.

For those who still want to hold physical gold without the additional costs of jewellery, gold coins offer the best of both worlds – purity and practicality.

<span style="font-bold">Conclusion: Which Gold Investment is Right for You?</span>

As you celebrate Dhanteras, think beyond traditional gold ornaments. Whether you prefer the convenience of Digital Gold, the flexibility of Gold ETFs, or the flicker of Gold Coins, each option offers its unique advantages.

For modern investors, Gold ETFs provide the best liquidity and cost efficiency. If you’re looking for convenience with the added advantage of small, incremental investments, Digital Gold is your go-to choice. Finally, for those with a penchant for physical gold, Gold Coins offer a cost-effective alternative with greater flexibility than ornaments. However, there are several things like management fees, storage challenges, and trustworthy sources you would like to consider before investing in any of these forms.

Whichever path you choose, remember that gold is not just an auspicious ornament but a sound investment for securing your financial future. So, this Dhanteras, unlock prosperity by making strategic gold investments tailored to your financial goals.`,

              author: "Resolute Corp",
              date: "29-10-2024",
              category: "Investing",
              banner: blog4,
            }
  
];

const BlogPost = ({ post, isExpanded, onToggle }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden">
    <div className="relative">
      <img src={post.banner} alt={post.title} className="w-full h-auto object-cover" />
      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4">
        <h2 className="md:text-3xl text-sm font-semibold mb-2">{post.title}</h2>
        <span className="text-sm">{post.date}</span>
        <span className="text-sm ml-4">{post.author}</span>
      </div>
    </div>
    <div className="p-6">
      <p className="text-gray-600 mb-4">{post.excerpt}</p>
      <button 
        onClick={onToggle}
        className="text-[#0E4669] font-medium flex items-center hover:text-blue-600"
      >
        {isExpanded ? (
          <>Read Less <ChevronUp className="ml-2 h-4 w-4" /></>
        ) : (
          <>Read More <ChevronDown className="ml-2 h-4 w-4" /></>
        )}
      </button>
    </div>
    {isExpanded && (
      <div className="px-6 py-4 bg-gray-50">
        <p className="text-gray-700 whitespace-pre-line" dangerouslySetInnerHTML={{ __html: post.content }}/>
      </div>
    )}
  </div>
);

const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [expandedPost, setExpandedPost] = useState(null);

  const categories = useMemo(() => {
    return ["All", ...new Set(blogPosts.map(post => post.category))];
  }, []);

  const filteredPosts = useMemo(() => {
    return selectedCategory === "All" 
      ? blogPosts 
      : blogPosts.filter(post => post.category === selectedCategory);
  }, [selectedCategory]);

  const toggleExpand = (postId) => {
    setExpandedPost(expandedPost === postId ? null : postId);
  };

  return (
    <section className="bg-[#F4F5F6]">
      <Helmet>
      <title>ResoluteCorp - Finance and Investment Centric Blog</title>
      <meta name="description" content="Explore insights on investments and growth with Resolute Group's expert analyses and strategies for navigating dynamic markets and achieving sustainable success."/>
      <meta name="keywords" content="Investment blog, global investment firm, invest in global markets, investment firm, distinguished investment firm in India"/>
      <meta name="robots" content="index,follow"/>
      </Helmet>
      <div className="pb-6">
      <div className='bg-[#0E4669] text-white py-16 px-8 pt-6 h-410 md:p-6 lg:p-12'
          style={{
            backgroundImage: `url(${portfolioBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '250px'
          }}
        ><h1 className="text-4xl font-bold text-center mb-3 py-5">Blogs</h1>
        <h4 className='text-center text-xl mb-5 font-semibold'>Insights on Investments and Growth</h4>
        </div>
        <div className="flex flex-wrap justify-center m-4">
          {categories.map(category => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`m-2 px-4 py-1 rounded-lg ${
                selectedCategory === category
                  ? "bg-[#0E4669] text-white"
                  : "bg-white text-[#0E4669] hover:bg-blue-100"
              }`}
            >
              {category}
            </button>
          ))}
        </div>

        <div className="grid md:grid-cols-2 gap-8 container mx-auto px-4  ">
          {filteredPosts.map(post => (
            <BlogPost
              key={post.id}
              post={post}
              isExpanded={expandedPost === post.id}
              onToggle={() => toggleExpand(post.id)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;